import React, {useEffect, useState} from 'react';
import Modal from './Modal';
import {BrowserRouter as Router, Route, Routes, useNavigate, useParams} from 'react-router-dom';

const API_ENDPOINTS = {
    ItchyBeard: "https://fortniteapi.io/v1/stats?account=1cfcb321dc564e4fb6a27cfa0653b906",
    Itchy_Beard: "https://fortniteapi.io/v1/stats?account=9fb71fa1b26a4e019ff649cb12b7c43d"
};

const HEADERS = {
    "Authorization": "4042572f-c5fa3ef0-217ccd74-cbfab6ae"
};

function App() {
    const {level} = useParams();
    const navigate = useNavigate();
    const [battlepassData, setBattlepassData] = useState(null);
    const [accounts, setAccounts] = useState(Object.keys(API_ENDPOINTS));
    const [selectedAccount, setSelectedAccount] = useState('');
    const [playerLevels, setPlayerLevels] = useState({});
    const [purchasedItems, setPurchasedItems] = useState({});
    const [levelsLeft, setLevelsLeft] = useState(0);
    const [daysLeft, setDaysLeft] = useState(0);
    const [levelsPerDay, setLevelsPerDay] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetch('/battlepass_data.json')
            .then(response => response.json())
            .then(data => {
                setBattlepassData(data);
                const currentDate = new Date();
                const seasonEndDate = new Date(data.seasonDates.end);
                const daysRemaining = Math.ceil((seasonEndDate - currentDate) / (1000 * 60 * 60 * 24));
                setDaysLeft(daysRemaining);
            })
            .catch(error => console.error('Error fetching data:', error));

        const storedPlayerLevels = localStorage.getItem('playerLevels');
        if (storedPlayerLevels) {
            setPlayerLevels(JSON.parse(storedPlayerLevels));
        }

        const storedPurchasedItems = localStorage.getItem('purchasedItems');
        if (storedPurchasedItems) {
            setPurchasedItems(JSON.parse(storedPurchasedItems));
        }
    }, []);

    useEffect(() => {
        if (battlepassData && selectedAccount) {
            const playerLevel = playerLevels[selectedAccount] || 1;
            const starsEarned = playerLevel * 5;
            let totalStars = 0;
            const unlocked = battlepassData.rewards.filter(reward => {
                totalStars += reward.price.amount;
                return totalStars <= starsEarned;
            });

            const maxLevel = 200;
            const levelsToReachMax = maxLevel - playerLevel;
            setLevelsLeft(levelsToReachMax);

            const currentDate = new Date();
            const seasonEndDate = new Date(battlepassData.seasonDates.end);
            const daysRemaining = Math.ceil((seasonEndDate - currentDate) / (1000 * 60 * 60 * 24));
            setDaysLeft(daysRemaining);
            const idealLevelsPerDay = (levelsToReachMax / daysRemaining).toFixed(2);
            setLevelsPerDay(idealLevelsPerDay);
        } else {
            setLevelsLeft(0);
            setLevelsPerDay(0);
        }
    }, [playerLevels, battlepassData, selectedAccount]);

    useEffect(() => {
        if (level !== undefined && level !== playerLevels[selectedAccount]) {
            setPlayerLevels(prev => ({...prev, [selectedAccount]: level}));
            navigate(`/${level}`);
        }
    }, [level, selectedAccount, playerLevels, navigate]);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedItem(null);
    };

    const handlePurchaseToggle = (offerId, e) => {
        e.stopPropagation(); // Prevent the click event from propagating to the parent div
        setPurchasedItems(prev => {
            const updatedPurchasedItems = {
                ...prev,
                [selectedAccount]: {
                    ...prev[selectedAccount],
                    [offerId]: !prev[selectedAccount]?.[offerId]
                }
            };
            localStorage.setItem('purchasedItems', JSON.stringify(updatedPurchasedItems));
            return updatedPurchasedItems;
        });
    };

    const handleAccountChange = (e) => {
        setSelectedAccount(e.target.value);
    };

    const fetchUserData = (accountName) => {
        const endpoint = API_ENDPOINTS[accountName];
        fetch(endpoint, {headers: HEADERS})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data.result && data.account) {
                    setPlayerLevels(prev => {
                        const updatedLevels = {...prev, [accountName]: data.account.level};
                        localStorage.setItem('playerLevels', JSON.stringify(updatedLevels));
                        return updatedLevels;
                    });
                } else {
                    console.error('Error fetching user data:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
                fetch(endpoint, {headers: HEADERS})
                    .then(response => response.text())
                    .then(text => console.error('Response text:', text));
            });
    };

    const claimAllRewards = () => {
        const allRewards = {};

        battlepassData.rewards.forEach(reward => {
            allRewards[reward.offerId] = true;
        });

        console.log(selectedAccount);

        setPurchasedItems(prev => {
            const updatedPurchasedItems = {...prev, [selectedAccount]: allRewards};
            localStorage.setItem('purchasedItems', JSON.stringify(updatedPurchasedItems));
            return updatedPurchasedItems;
        });
    };

    if (!battlepassData) {
        return <div>Loading...</div>;
    }

    const {displayInfo, seasonDates, rewards} = battlepassData;

    return (
        <div className="App">
            <header className="App-header">
                {/*<h2 className="highlight">WRECKED</h2>*/}
                <img src="./abs-doom-logo.png" height="240px" style={{margin: "0 auto"}} />
                <h1>{displayInfo.battlepassName}</h1>
                <h2>{displayInfo.chapterSeason}</h2>
                <p>Season
                    Dates: {new Date(seasonDates.begin).toLocaleDateString()} - {new Date(seasonDates.end).toLocaleDateString()}
                    <br/> <strong className="highlight">({daysLeft} days remaining)</strong></p>
                {levelsLeft > 0 && (<p>{levelsLeft} levels left ({levelsPerDay} levels per day)</p>)}
                {playerLevels[selectedAccount] >= 200 && (
                    <div className="alert alert-info">
                        <h3 style={{marginBottom: 0}}>Congrats! You're able to unlock all rewards!</h3>
                    </div>
                )}
                <select value={selectedAccount} onChange={handleAccountChange}>
                    {accounts.map((account, index) => (
                        <option key={index} value={account}>{account} ({playerLevels[account] || 0})</option>
                    ))}
                </select>
                <br/>
                <div className="buttonlinks">
                    <button onClick={() => fetchUserData(selectedAccount)}>Fetch User Data <i
                        className="fa-solid fa-rotate-right"></i></button>
                    <button onClick={claimAllRewards}>Claim All Rewards <i className="fa-solid fa-thumbs-up"></i>
                    </button>
                    <a className="button" href="/bosses">View Bosses <i className="fa-solid fa-handshake"></i></a>
                </div>
                <div className="container grid items">
                    {rewards.map((reward, index) => {
                        const isPurchased = purchasedItems[selectedAccount]?.[reward.offerId];

                        let itemClass = 'item locked';

                        if (isPurchased) {
                            itemClass = 'item purchased';
                        }

                        return (
                            <div
                                key={index}
                                className={`${itemClass} ${reward.item.rarity.id.toLowerCase()} ${isPurchased && ' unlocked'}`}
                                onClick={() => handleItemClick(reward.item)}
                            >
                                <img src={reward.item.images.icon} alt={reward.item.name}/>
                                <div className="reward-status">
                                    <input className="form-check-input" type="checkbox"
                                           checked={isPurchased || false}
                                           onChange={(e) => handlePurchaseToggle(reward.offerId, e)}
                                           onClick={(e) => e.stopPropagation()}/>
                                </div>
                                <div className="stars">{reward.price.amount} <img src="./battlestaricon.png"
                                                                                  alt="Star"/></div>
                                <p> {reward.item.name}{reward.quantity > 1 && (<> x{reward.quantity}</>)}</p>
                            </div>
                        );
                    })}
                </div>
            </header>
            <Modal show={showModal} onClose={handleCloseModal} item={selectedItem}/>
        </div>
    );
}

const Boss = ({image, name, special, description, loot}) => {
    return (
        <div className="container">
            <div className="grid boss">
                <img src={image} alt={`${name} image`}/>
                <div>
                    <h1>{name}</h1>
                    {special && (<h4>{special}</h4>)}
                    <p>{description}</p>

                    <h2 className="highlight">Notable Loot</h2>
                    <div className="grid loot">
                        {loot.map((item, index) => (
                            <div key={index} className={`item ${item.rarity}`}>
                                <img src={item.image} alt={item.name}/>
                                <p>{item.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const bosses = [
    {
        image: "./images/MegaloDon.webp",
        name: "Megalo Don",
        special: "Infused with unlimited Nitro",
        description: "Can be found at Brutal Beachhead or Patrolling the Map.",
        loot: [
            {image: "./images/medallion/megalodon.webp", name: "Megalo Don's Medallion", rarity: "legendary"},
            {image: "./images/items/nitro_fists.webp", name: "Megalo Don's Nitro Fists", rarity: "mythic"},
            {image: "./images/items/combat_shotgun.webp", name: "Megalo Don's Combat Shotgun", rarity: "mythic"},
            {image: "./images/items/nitro_splash.webp", name: "Nitro Splash (x3)", rarity: "rare"},
            {image: "./images/items/flowberry_fizz.webp", name: "FlowBerry Fizz", rarity: "rare"},
            {image: "./images/items/gold_bar.webp", name: "Gold Bar (x12)", rarity: "common"},
        ]
    },
    {
        image: "./images/TheMachinist.webp",
        name: "The Machinist",
        special: "Regenerates Shield.",
        description: "Can be found at Redline Rig or Patrolling the Map.",
        loot: [
            {image: "./images/medallion/machinist.webp", name: "The Machinist's Medallion", rarity: "legendary"},
            {image: "./images/items/combat_assault_rifle.webp", name: "The Machinist's Combat Assault Rifle", rarity: "mythic"},
            {image: "./images/items/nitro_splash.webp", name: "Nitro Splash (x3)", rarity: "rare"},
            {image: "./images/items/flowberry_fizz.webp", name: "FlowBerry Fizz", rarity: "rare"},
            {image: "./images/items/gold_bar.webp", name: "Gold Bar (x12)", rarity: "common"},
        ]
    },
    {
        image: "./images/RingmasterScarr.webp",
        name: "Ringmaster Scarr",
        special: "Infinite ammo and a small damage buff.",
        description: "Can only be found at the Nitrodrome.",
        loot: [
            {image: "./images/medallion/ringmaster_scarr.webp", name: "Ringmaster Scarr's Medallion", rarity: "legendary"},
            {image: "./images/items/boom_bolt.webp", name: "Ringmaster's Boom Bolt", rarity: "mythic"},
            {image: "./images/items/nitro_splash.webp", name: "Nitro Splash (x3)", rarity: "rare"},
            {image: "./images/items/flowberry_fizz.webp", name: "FlowBerry Fizz", rarity: "rare"},
            {image: "./images/items/gold_bar.webp", name: "Gold Bar (x12)", rarity: "common"},
        ]
    },
    {
        image: "./images/Cerberus.webp",
        name: "Cerberus",
        description: "Can only be found at Grim Gate.",
        loot: [
            {image: "./images/items/gatekeeper_shotgun.webp", name: "Cerberus' Gatekeeper Shotgun", rarity: "mythic"}
        ]
    },
    {
        image: "./images/Oscar.webp",
        name: "Oscar",
        description: "Can only be found at Classy Courts.",
        loot: [
            {image: "./images/items/frenzy_auto_shotgun.webp", name: "Oscar's Frenzy Auto Shotgun", rarity: "mythic"}
        ]
    },
];


function Bosses() {
    const [battlepassData, setBattlepassData] = useState(null);
    const [daysLeft, setDaysLeft] = useState(0);

    useEffect(() => {
        fetch('/battlepass_data.json')
            .then(response => response.json())
            .then(data => setBattlepassData(data))
            .catch(error => console.error('Error fetching data:', error));

    }, []);

    if (!battlepassData) {
        return <div>Loading...</div>;
    }

    const {displayInfo} = battlepassData;

    return (
        <div className="App">
            <h2 className="highlight">WRECKED</h2>
            <h1>Bosses</h1>
            <h2>{displayInfo.chapterSeason}</h2>
            <div className="buttonlinks">
                <a className="button" href="/">Battlepass <i className="fa-solid fa-ticket"></i></a>
            </div>


            <div>
                {bosses.map((boss, index) => (
                    <Boss
                        key={index}
                        image={boss.image}
                        name={boss.name}
                        special={boss.special}
                        description={boss.description}
                        loot={boss.loot}
                    />
                ))}
            </div>
        </div>
    )
}


function AppWrapper() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/bosses" element={<Bosses/>}/>
            </Routes>
        </Router>
    );
}

export default AppWrapper;
