import React from 'react';

function Modal({ show, onClose, item }) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal" onClick={onClose}>
            <div className={`modal-content ${item.rarity.id}`} onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h4 className="modal-title">{item.name}</h4>
                </div>
                <div className="modal-body">
                    <p>{item.description}</p>
                    <p><strong>Rarity:</strong> {item.rarity.name}</p>
                    <p><strong>Type:</strong> {item.type.name}</p>
                    <img src={item.images.icon} alt={item.name} />
                </div>
                <div className="modal-footer">
                    <button onClick={onClose} className="button">Close</button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
